import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { isCrushAppMobile } from 'utils/userAgentCheck';
import { auth } from 'lib/firebase/firebase-config';
import { swapIDToken } from 'api/user';

export const MIGRATION_REMIND_COOKIE = 'krush_reminder';
export const MIGRATION_ACCEPT_COOKIE = 'krush_redirect';

const DOMAIN_MAPPINGS = {
  'dev': 'http://staging.krush.chat',
  'staging': 'https://staging.krush.chat',
  'production': 'https://www.krush.chat',
} as const;

export const useDomainMigration = () => {
  const [showMigrationModal, setShowMigrationModal] = useState(false);
  const [targetDomain, setTargetDomain] = useState('');
  const [debugToken, setDebugToken] = useState<string | null>(null);

  const migrateToNewDomain = async (newTargetDomain: string) => {
    try {
      // Skip if already on krush.chat domain
      if (window.location.hostname.endsWith('krush.chat'))
        return;

      const currentUser = auth.currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        const customToken = await swapIDToken(idToken);
        setDebugToken(customToken);
        window.location.href = `${newTargetDomain}/?t=${customToken}`;
      } else {
        window.location.href = newTargetDomain;
      }
    } catch (error) {
      console.error('Migration error:', error);
      window.location.href = newTargetDomain;
    }
  };

  useEffect(() => {
    const checkDomain = async () => {
      const currentDomain = window.location.hostname;
      const isInWebView = isCrushAppMobile();
      const wasReminded = Cookies.get(MIGRATION_REMIND_COOKIE);

      // Skip if already on krush.chat domain
      if (currentDomain.endsWith('krush.chat')) {
        setShowMigrationModal(false);
        return;
      }

      // Skip for localhost
      if (currentDomain === 'localhost') {
        setShowMigrationModal(false);
        return;
      }

      const shouldShowModal = (!isInWebView && !wasReminded);

      let newTargetDomain = '';
      if (currentDomain === 'localhost') {
        newTargetDomain = DOMAIN_MAPPINGS.dev;
      } else if (currentDomain === 'staging.crush.to') {
        newTargetDomain = DOMAIN_MAPPINGS.staging;
      } else {
        newTargetDomain = DOMAIN_MAPPINGS.production;
      }

      setShowMigrationModal(shouldShowModal);
      setTargetDomain(newTargetDomain);
    };

    checkDomain();
  }, []);

  return {
    showMigrationModal,
    closeMigrationModal: () => setShowMigrationModal(false),
    targetDomain,
    migrateToNewDomain,
    debugToken
  };
};