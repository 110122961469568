import Cookies from 'js-cookie';
import { reportAnalyticsEvent } from 'utils/analytics';

class AuthStorage {
  private static DB_NAME = 'crush_auth';
  private static STORE_NAME = 'session';
  private static KEY = 'session_id';
  private db: IDBDatabase | null = null;

  // Initialize IndexedDB
  private async initDB(): Promise<IDBDatabase> {
    if (this.db) return this.db;

    return new Promise((resolve, reject) => {
      const request = indexedDB.open(AuthStorage.DB_NAME, 1);

      request.onerror = () => reject(request.error);

      request.onsuccess = () => {
        this.db = request.result;
        resolve(this.db);
      };

      request.onupgradeneeded = (event) => {
        const db = request.result;
        if (!db.objectStoreNames.contains(AuthStorage.STORE_NAME)) {
          db.createObjectStore(AuthStorage.STORE_NAME);
        }
      };
    });
  }

  async setSessionId(sessionId: string) {
    try {
      // Set in IndexedDB
      const db = await this.initDB();
      const transaction = db.transaction(AuthStorage.STORE_NAME, 'readwrite');
      const store = transaction.objectStore(AuthStorage.STORE_NAME);
      await new Promise((resolve, reject) => {
        const request = store.put(sessionId, AuthStorage.KEY);
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      // Set in localStorage as backup
      localStorage.setItem(AuthStorage.KEY, sessionId);

      // Set in cookie for traditional support
      Cookies.set(AuthStorage.KEY, sessionId, {
        secure: true,
        sameSite: 'strict'
      });

      return true;
    } catch (error) {
      reportAnalyticsEvent('Set Token Error 3', {
        error: error instanceof Error ? error.message : String(error)
      });
      console.error('Error setting session:', error);
      return false;
    }
  }

  async getSessionId(): Promise<string | null> {
    try {
      // Try IndexedDB first
      const db = await this.initDB();
      const transaction = db.transaction(AuthStorage.STORE_NAME, 'readonly');
      const store = transaction.objectStore(AuthStorage.STORE_NAME);
      const sessionId = await new Promise<string>((resolve, reject) => {
        const request = store.get(AuthStorage.KEY);
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      if (sessionId) return sessionId;

      // Try localStorage as fallback
      const localSessionId = localStorage.getItem(AuthStorage.KEY);
      if (localSessionId) {
        // Sync back to IndexedDB if found in localStorage
        await this.setSessionId(localSessionId);
        return localSessionId;
      }

      // Try cookie as last resort
      const cookieSessionId = Cookies.get(AuthStorage.KEY);
      if (cookieSessionId) {
        // Sync to other storages if found in cookie
        await this.setSessionId(cookieSessionId);
        return cookieSessionId;
      }

      return null;
    } catch (error) {
      console.error('Error getting session:', error);
      // Try cookie as emergency fallback
      return Cookies.get(AuthStorage.KEY) || null;
    }
  }

  async clearSession() {
    try {
      // Clear IndexedDB
      const db = await this.initDB();
      const transaction = db.transaction(AuthStorage.STORE_NAME, 'readwrite');
      const store = transaction.objectStore(AuthStorage.STORE_NAME);
      await new Promise((resolve, reject) => {
        const request = store.delete(AuthStorage.KEY);
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      // Clear localStorage
      localStorage.removeItem(AuthStorage.KEY);

      // Clear cookie
      Cookies.remove(AuthStorage.KEY);

      return true;
    } catch (error) {
      console.error('Error clearing session:', error);
      return false;
    }
  }
}

export const authStorage = new AuthStorage();