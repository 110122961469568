import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import Bugsnag from 'utils/bugsang';
import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from 'react-query';
import { useModalsStore } from 'store/modals';
import { ScreenLayout } from 'components/shells/ScreenLayout';
import { AuthHolder } from 'components/skeletons/AuthHolder';
import { reactQuery } from 'lib/reactQuery';
import { useComputeRefer } from 'hooks/useComputeRefer';
import { useRouter } from 'next/router';
import { setStorageItem } from '../utils/storage';
import { useSocketChat } from 'hooks/useSocketChat';
import { useAuthStore } from 'store/auth';
import { noIndexPages } from 'utils/hardcoded-data';
import { customTokenSignIn } from 'api/auth';
import { isCrushAppMobile } from 'utils/userAgentCheck';
import { verifyAndRefreshToken } from 'utils/tokenVerification';
import Script from 'next/script';

import 'styles/globals.scss';
import 'styles/blog.scss';

const isAppMobile = isCrushAppMobile();

// const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || (({ children }) => children);

const SocketLogic = () => {
  useSocketChat();
  return <></>;
};

const Root = (props: AppProps) => {
  const { query, pathname, push, replace, asPath } = useRouter();
  const { setIsOpenAuthModal } = useModalsStore();
  const { user } = useAuthStore();

  useComputeRefer();

  useEffect(() => {
    if (query.utm_source && query.utm_source === 'ja' && query.jaid) setStorageItem('jaid', query.jaid);
    if (query.t) customTokenSignIn(query.t as string).then(() => {
      const url = new URL(window.location.href);
      url.searchParams.delete('t');
      window.history.replaceState(null, '', url.toString());
    });
  }, [query]);

  useEffect(() => {
    if (window !== undefined && window.crush) {
      window.crush.handleSwipeLeft = function () {
        window.history.back();
      };
    }
  }, []);

  useEffect(() => {
    // Only run on initial page load
    if (typeof window !== 'undefined' && !window.__tokenVerified) {
      window.__tokenVerified = true;
      void verifyAndRefreshToken();
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onerror = function (message, source, lineno, colno, error) {
        if ((error?.message?.includes('auth/custom-token-mismatch') ||
             error?.message?.includes('auth/invalid-custom-token')) &&
            window.webkit?.messageHandlers?.errorHandler) {
          window.webkit.messageHandlers.errorHandler.postMessage({
            error: error.message
          });
        }
      };
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleHash = () => {
        const hash = window.location.hash.toLowerCase();
        if (!user) {
          if (hash === '#signup' || hash === '#signin') {
            setIsOpenAuthModal(true);
          }
        }
        // Clear the hash after processing
        if (hash === '#signup' || hash === '#signin') {
          window.history.replaceState(null, '', window.location.pathname + window.location.search);
        }
      };

      // Handle hash on initial load
      handleHash();

      // Listen for hash changes
      window.addEventListener('hashchange', handleHash);
      return () => window.removeEventListener('hashchange', handleHash);
    }
  }, [user, setIsOpenAuthModal]);

  return (
    <>
    {/* <ErrorBoundary> */}
      <Head>
        {isAppMobile ? (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"
          />
        ) : (
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        )}

        <title>KrushChat AI - AI Girlfriend & Companion for NSFW Roleplay</title>
        <meta name="theme-color" content="#1f1f1f" />
        <meta name="title" content="KrushChat AI - AI Girlfriend & Companion for NSFW Roleplay" />
        <meta
          name="description"
          content="Experience KRUSH AI: the best AI Girlfriend and AI Companion. Create your own AI Chatbot and generate beautiful images for AI NSFW and erotic roleplay."
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="canonical"
          href={`https://www.krush.chat${asPath}`}
        />

        {/* Open Graph */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.krush.chat${asPath}`} />
        <meta property="og:title" content="KrushChat AI - AI Girlfriend & Companion for NSFW Roleplay" />
        <meta
          property="og:description"
          content="Experience KRUSH AI: the best AI Girlfriend and AI Companion. Create your own AI Chatbot and generate beautiful images for AI NSFW and erotic roleplay."
        />
        <meta property="og:image" content="/images/crush-banner-1200x630.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.krush.chat/" />
        <meta property="twitter:title" content="KrushChat AI - AI Girlfriend & Companion for NSFW Roleplay" />
        <meta
          property="twitter:description"
          content="Experience KRUSH AI: the best AI Girlfriend and AI Companion. Create your own AI Chatbot and generate beautiful images for AI NSFW and erotic roleplay."
        />
        <meta property="twitter:image" content="/images/crush-banner-1200x675.jpg" />

        {/* SEO */}
        <meta
          name="keywords"
          content="AI NSFW, AI Girlfriend, AI Companion, NSFW Chatbot, Free AI Chatbot, Free AI, NSFW Image Generator, NSFW AI Chatbot, Free NSFW, ERP, erotic roleplay"
        />

        {noIndexPages.some((path) => pathname.includes(path)) ? (
          <meta name="robots" content="noindex" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}

        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="KrushChat AI" />

        {/* PWA */}
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <meta name="apple-mobile-web-app-title" content="KrushChat AI" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      </Head>
      <QueryClientProvider client={reactQuery}>
        {isAppMobile && !user && <AuthHolder />}
        {user && <SocketLogic />}

        <Script
          id="google-analytics"
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_MEASUREMENT_ID}');
          ${process.env.VERCEL_ENV === 'production' ? `gtag('config', '${process.env.NEXT_PUBLIC_ADWORDS_ID}');` : ''}`
          }}
        />

        <Script
          id="posthog"
          data-turbo-eval="false"
          dangerouslySetInnerHTML={{
            __html: `
        !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys onSessionId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
        posthog.init('${process.env.NEXT_PUBLIC_POSTHOG_KEY}',{api_host:'https://app.posthog.com',autocapture: false})
        `
          }}
        />

        <Script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_MEASUREMENT_ID}`} />

        <Script
          id="tiktok-pixel"
          dangerouslySetInnerHTML={{
            __html: `
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
            var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
            ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
              ttq.load('CTGO0U3C77U9AUGH37F0');
              ttq.page();
            }(window, document, 'ttq');
            `
          }}
        />

        <ScreenLayout {...props} />
        <ToastContainer
          position="bottom-center"
          theme="dark"
          autoClose={3000}
          closeOnClick
          className="custom-toast-container"
          closeButton={false}
          limit={3}
        />
      </QueryClientProvider>
    {/* </ErrorBoundary> */}
    </>
  );
};

export default Root;
