import Link from 'next/link';
import { SocialIcons } from './SocialIcons';
import { SidebarNav } from './SidebarNav';
import { useAuthStore } from 'store/auth';
import { useModalsStore } from 'store/modals';
import { CoinBalance } from './CoinBalance';
import { useIsPWA } from 'hooks/usePWA';
import { Logo } from 'components/icons/Logo';
import { Heart } from 'components/icons/Heart';
import { Login } from 'components/icons/Login';
import Image from 'next/image'

type Props = {
  isExpanded: boolean;
};

export const Sidebar = ({ isExpanded }: Props) => {
  const { user, isLoading } = useAuthStore();
  const { setIsOpenAuthModal } = useModalsStore();

  const { isPWA } = useIsPWA();

  return (
    <div
      className={`h-full py-6 px-4 hidden lg:flex flex-col border-r border-primary bg-primary fixed top-0 bottom-0 left-0 z-[100] trans ${isExpanded ? 'w-[250px]' : 'w-[90px] items-center'}`}
    >
      <Link href="/" className={`w-fit flex mb-10 ${isExpanded ? 'ml-3' : ''}`} prefetch={false}>
        {isExpanded ? (
          <Image
            src="/krush-white-logo-transparent.png"
            alt="Krush Logo"
            width={80}
            height={30}
            priority
            className="mr-3"
          />
        ) : ''}
        <Heart className="text-danger" />
      </Link>
      <SidebarNav isExpanded={isExpanded} />

      <div className="flex flex-col mt-auto gap-5 items-center justify-center">
        {user ? (
          <CoinBalance />
        ) : !isLoading && (
          <button
            suppressHydrationWarning
            className="cy-auth-button btn outlined primary"
            onClick={() => setIsOpenAuthModal(true)}
          >
            {isExpanded ? isPWA ? 'Login' : 'Login / Register' : <Login className="w-5 h-5" />}
          </button>
        )}
        <SocialIcons isExpanded={isExpanded} />
      </div>
    </div>
  );
};
