import { Discord } from 'components/icons/Discord';
import { Instagram } from 'components/icons/Instagram';
import { Reddit } from 'components/icons/Reddit';
import { Twitter } from 'components/icons/Twitter';
import { IconProps } from 'utils/global.types';

type Props = {
  isExpanded: boolean;
};

export const SocialIcons = ({ isExpanded }: Props) => {
  const socialIcons = [
    {
      name: 'Reddit',
      icon: (props: IconProps) => <Reddit {...props} />,
      url: 'https://www.reddit.com/r/crush_official/'
    },
    {
      name: 'Discord',
      icon: (props: IconProps) => <Discord {...props} />,
      url: 'https://discord.gg/SXJTunpQz5'
    }
  ];

  if (isExpanded) {
    const newItems = [
      {
        name: 'Instagram',
        icon: (props: IconProps) => <Instagram {...props} />,
        url: 'https://www.instagram.com/krushchat'
      },
      // {
      //   name: 'Twitter',
      //   icon: (props: IconProps) => <Twitter {...props} />,
      //   url: 'https://twitter.com/_CrushAI'
      //   // },
      //   // {
      //   //   name: 'Youtube',
      //   //   icon: icons.youtube,
      //   //   url: 'https://www.youtube.com/@crushapp'
      // }
    ];

    socialIcons.push(...newItems);
  }

  return (
    <div className="flex items-center justify-center gap-3 flex-wrap">
      {socialIcons.map((social) => (
        <a
          key={social.name}
          href={social.url}
          target="_blank"
          rel="noreferrer"
          className="w-10 h-10 flex items-center justify-center text-white text-opacity-20 border border-white border-opacity-20 rounded-full trans hover:text-opacity-50 hover:border-opacity-50 transform hover:scale-105"
        >
          {social.icon({ className: 'w-5 h-5' })}
        </a>
      ))}
    </div>
  );
};
