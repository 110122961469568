import apiCall from 'api';
import {
  BecomeAffiliatePayload,
  PostFeedbackPayload,
  UpdateUserBody,
  User,
  RewardTask,
  ClaimRewardTaskPayload
} from './types';

import { setStorageItem } from 'utils/storage';
import { useAuthStore } from 'store/auth';
import Cookies from 'js-cookie';
import { reportBug } from 'utils';
import axios from 'axios';
import { showToast } from 'components/ui/CustomToast';
import { reportAnalyticsEvent } from 'utils/analytics';
import { FetchBotGalleryResponse } from 'api/conversation/types';

export const socketURL = `${process.env.NEXT_PUBLIC_WS_URL}/cable`;

export const getMe = async () => {
  try {
    const request = await apiCall();
    const { data } = await request<User>({ url: `users/me`, method: 'GET' });

    let user = { ...data };

    if (!Boolean(data.custom_attributes.default_language) && Boolean(data.custom_attributes.accept_tos_18)) {
      const { data: updatedUser } = await request({
        url: `users/me`,
        method: 'PATCH',
        data: { custom_attributes: { default_language: navigator.language } }
      });

      user = { ...user, custom_attributes: updatedUser.custom_attributes };
    }

    useAuthStore.getState().setUser(user);
    setStorageItem('user', user);
    Cookies.set('userID', user.uuid);

    return data;
  } catch (error: any) {
    // More comprehensive error logging
    const errorDetails = {
      message: error?.data?.message || error?.message || String(error),
      status: error?.status || error?.response?.status,
      data: error?.data,
      stack: error?.stack,
    };

    reportAnalyticsEvent('GetMe Error', errorDetails);
    // reportBug({
    //   msg: errorDetails.message,
    //   error: errorDetails
    // });

    console.error('GetMe Error:', errorDetails);
    return null;
  }
};

interface SessionResponse {
  data: {
    session_id: string;
  };
}

export const setToken = async () => {
  try {
    const request = await apiCall();
    const { data } = await request<SessionResponse>({
      url: `setToken`,
      method: 'POST',
    });
    return data.data.session_id;
  } catch (error: any) {
    reportAnalyticsEvent('Set Token Error', { error: error?.data?.message });
    reportBug({ msg: error?.data?.message, error });
    return null;
  }
}

export const updateMe = async (data: UpdateUserBody) => {
  try {
    const request = await apiCall();
    const { data: user } = await request({
      url: `users/me`,
      method: 'PATCH',
      data
    });

    if (user) useAuthStore.getState().setUser(user);

    await getMe();
    return user;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const postFeedback = async (data: PostFeedbackPayload) => {
  try {
    const request = await apiCall();
    await request({ url: `users/feedback`, method: 'POST', data });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
  }
};

export const postFeedbackPrompted = async () => {
  try {
    const request = await apiCall();
    await request({ url: `users/feedbackPrompted`, method: 'POST' });
  } catch (error: any) {
  }
};

export const becomeAffiliate = async (payload: BecomeAffiliatePayload) => {
  try {
    await axios.post('/api/create-affiliate', payload);

    showToast({ message: `Application received! We'll contact you shortly.`, type: 'success' });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
  }
};

export const updateAvatar = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/me/avatar`,
      method: 'POST',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    useAuthStore.getState().setUser(data);
    setStorageItem('user', data);
    return data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const deleteUserMe = async (): Promise<number> => {
  try {
    const request = await apiCall();
    const { status } = await request({ url: `users/me`, method: 'DELETE' });
    return status;
  } catch (err: any) {
    reportBug({ msg: err?.data?.message, error: err });
    return Promise.reject(err);
  }
};

export const getRewardTasks = async (): Promise<RewardTask[]> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `users/getRewardTasks`, method: 'GET' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const claimRewardCode = async (code: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/claimRewardCode?code=${encodeURIComponent(code.toLowerCase())}`,
      method: 'POST'
    });

    return data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const claimRewardTask = async (payload: ClaimRewardTaskPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/claimRewardTask`,
      method: 'POST',
      data: payload
    });

    return data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const swapIDToken = async (idToken: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: 'users/swapIDToken',
      method: 'POST',
      data: { id_token: idToken }
    });
    return data.data.token;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const getUserAssets = async (page = 0, size = 48): Promise<FetchBotGalleryResponse> => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `users/me/assets`,
      method: 'GET',
      params: { page, size }
    });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};