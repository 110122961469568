import Link from 'next/link';

import { useModalsStore } from 'store/modals';
import { useAuthStore } from 'store/auth';

import dynamic from 'next/dynamic';
import { Explore } from 'components/icons/Explore';
import { IconProps } from 'utils/global.types';
import { Inbox } from 'components/icons/Inbox';
import { Create } from 'components/icons/Create';
import { Crushes } from 'components/icons/Crushes';
import { Profile } from 'components/icons/Profile';
import { Coin } from 'components/icons/Coin';
import { props } from 'node_modules/cypress/types/bluebird';
import { Crown } from 'components/icons/Crown';
import { AddPhoto } from 'components/icons/AddPhoto';
const PWAButton = dynamic(import('components/ui/PWAButton'), { ssr: false });

type Props = {
  isExpanded: boolean;
};

type NavItem = {
  name: string;
  path: string;
  icon: (props?: IconProps) => JSX.Element;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  cypressClass?: string;
  badge?: string;
};

export const SidebarNav = ({ isExpanded }: Props) => {
  const { user, isLogged } = useAuthStore();
  const { setIsOpenAuthModal } = useModalsStore();

  const nav: NavItem[] = [
    {
      name: 'Explore',
      path: '/',
      icon: (props) => <Explore {...props} />
    },
    { name: 'Chat', path: '/chat', icon: (props) => <Inbox {...props} />, cypressClass: 'cy-chat-nav' },
    { name: 'Photos', path: '/studio', icon: (props) => <AddPhoto {...props} />, badge: 'New!' },
    { name: 'Create Krush', path: '/create', icon: (props) => <Create {...props} /> }
  ];

  if (isLogged) {
    nav.push({ name: 'My Krushes', path: '/my-krushes', icon: (props) => <Crushes {...props} /> });
    nav.push({
      name: 'Profile',
      path: '/settings',
      icon: (props) => <Profile {...props} />,
      cypressClass: 'cy-profile-nav'
    });
  }

  if (user?.is_premium) {
    nav.push({ name: 'Credits', path: '/credits', icon: (props) => <Coin {...props} /> });
  } else {
    nav.push({
      name: 'Join Premium',
      path: '/subscriptions',
      cypressClass: 'cy-premium-nav',
      icon: (props) => <Crown {...props} />,
      onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!user) {
          e.preventDefault();
          setIsOpenAuthModal(true);
        }
      }
    });
  }

  return (
    <nav className="flex flex-col gap-2">
      {nav.map((item) => (
        <Link
          prefetch={false}
          key={item.name}
          href={item.path}
          className={`${item.cypressClass ? item.cypressClass : ''} w-full flex items-center gap-4 rounded-lg hover:bg-primary p-3 ${isExpanded ? '' : 'justify-center'}`}
          onClick={item.onClick}
        >
          {item?.icon({ className: 'w-6 h-6' })}
          {isExpanded && <span className="text-sm font-medium">
            {item.name}
            {item.badge && (
              <span className="ml-2 bg-brand text-xs font-bold px-1.5 py-0.5 rounded-full">
                {item.badge}
              </span>
            )}
          </span>}
        </Link>
      ))}

      <PWAButton
        showText={isExpanded}
        className={`w-full flex items-center gap-4 rounded-lg hover:bg-primary p-3 ${isExpanded ? '' : 'justify-center'}`}
      />
    </nav>
  );
};
