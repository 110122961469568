import { getMe } from 'api/user';
import { reportAnalyticsEvent } from 'utils/analytics';
import { useEffect, useCallback } from 'react';
import { useAuthStore } from 'store/auth';
import { isCrushAppMobile } from 'utils/userAgentCheck';
import { useModalsStore } from 'store/modals';
import { authStorage } from 'utils/storage/auth';
import { showToast } from 'components/ui/CustomToast';

let authCheckInterval: NodeJS.Timeout | null = null;

export const useAuthCheck = () => {
  const { user, setUser, setIsLogged } = useAuthStore();
  const { setIsOpenAuthModal } = useModalsStore();

  useEffect(() => {
    // Only run checks if user is logged in and not on mobile.
    if (!user || isCrushAppMobile()) {
      console.log('Auth check skipped');
      return;
    }

    // Only setup if not already running
    if (authCheckInterval) return;

    const checkAuth = async () => {
      const result = await getMe();
      if (!result) {
        // First failure, wait 5s and retry
        reportAnalyticsEvent('auth_check_failed 1');
        const session_id = await authStorage.getSessionId();
        reportAnalyticsEvent('auth_check_session_id', { has_session_id: !!session_id, session_id: session_id });

        await new Promise(resolve => setTimeout(resolve, 5000));
        const retryResult = await getMe();

        if (!retryResult) {
          // Second failure, show modal and stop checking
          console.log('Auth check failed');
          reportAnalyticsEvent('auth_check_failed 2');

          const session_id = await authStorage.getSessionId();
          reportAnalyticsEvent('auth_check_session_id', { has_session_id: !!session_id, session_id: session_id });

          setIsOpenAuthModal(true);
          setUser(null);
          setIsLogged(false);
          await authStorage.clearSession();
          if (authCheckInterval) {
            clearInterval(authCheckInterval);
            authCheckInterval = null;
          }
        }
      }
    };

    // Start checking every 30s
    // console.log('Setting up auth check interval');
    authCheckInterval = setInterval(checkAuth, 30000);

    // Cleanup on unmount
    return () => {
      if (authCheckInterval) {
        // console.log('Clearing auth check interval');
        clearInterval(authCheckInterval);
        authCheckInterval = null;
      }
    };
  }, [user]); // Re-run when user changes
};