import Link from 'next/link';

import SilverCoin from 'public/images/silverCoin.png';
import GoldCoin from 'public/images/goldCoin.png';
import { useModalsStore } from 'store/modals';
import { SocialIcons } from './SocialIcons';
import { useAuthStore } from 'store/auth';
import { logout, testFirebaseLogout } from 'api/auth';
import dynamic from 'next/dynamic';
import { Coin } from 'components/icons/Coin';
import { Crown } from 'components/icons/Crown';
import { Logout } from 'components/icons/Logout';
import { Login } from 'components/icons/Login';

const PWAButton = dynamic(import('components/ui/PWAButton'), { ssr: false });

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const MobileMenu = ({ isOpen, onClose }: Props) => {
  const { isLogged, user } = useAuthStore();
  const { setIsOpenAuthModal } = useModalsStore();

  const CoinImage = user?.is_premium ? GoldCoin : SilverCoin;
  const coins = user?.credit_balance || 0;

  return (
    <>
      <div
        className={`lg:hidden fixed left-0 bottom-0 right-0 bg-primary rounded-t-3xl z-[100] trans transform ${
          isOpen ? 'translate-y-0' : 'translate-y-[400px]'
        }`}
      >
        <div className="h-full relative flex flex-col gap-2 px-4 pb-4">
          <div className="flex justify-center py-4" onClick={onClose} onTouchMove={onClose}>
            <div className="w-12 h-1.5 rounded-full bg-white bg-opacity-20" />
          </div>
          {isLogged && (
            <div className="flex items-center gap-1 bg-brand px-3 py-1 rounded-full w-fit mb-4 mx-auto">
              Credits:
              <div className="w-5 h-5 rounded-full">
                <img src={CoinImage.src} alt="Coin" className="w-full h-full object-cover rounded-full" />
              </div>
              <span className="font-semibold">{coins}</span>
            </div>
          )}
          {/*<Link href="/" className="flex items-center gap-2 p-3 rounded-xl border border-primary" onClick={onClose}>
            {icons.plus({ className: 'w-5 h-5' })}
            Create
          </Link>*/}
          {user?.is_premium ? (
            <Link
              prefetch={false}
              href="/credits"
              className="flex items-center gap-2 p-3 rounded-xl border border-primary"
              onClick={onClose}
            >
              <Coin className="w-7 h-5" />
              Credits
            </Link>
          ) : (
            <Link
              prefetch={false}
              href="/subscriptions"
              className="flex items-center gap-2 p-3 rounded-xl border border-primary"
              onClick={onClose}
            >
              <Crown className="w-7 h-5" />
              Premium
            </Link>
          )}
          {isLogged ? (
            <p className="flex items-center gap-2 p-3 rounded-xl border border-primary" onClick={() => logout('/')}>
              <Logout className="w-7 h-5" />
              Logout
            </p>
          ) : (
            <p
              className="flex items-center gap-2 p-3 rounded-xl border border-primary"
              onClick={() => setIsOpenAuthModal(true)}
            >
              <Login className="w-7 h-5" />
              Login
            </p>
          )}
          <PWAButton className={`flex items-center gap-2 p-3 rounded-xl border border-primary w-full`} />
          <div className="mt-4">
            <p className="text-center text-white text-opacity-30 mb-3">Follow Us:</p>
            <SocialIcons isExpanded />
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className="lg:hidden fixed inset-0 z-[99] flex items-center justify-center p-4 backdrop-blur-sm"
          onClick={onClose}
        />
      )}
    </>
  );
};
