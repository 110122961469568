import type { StylesConfig } from 'react-select';

const reactSelectStylesConfig: StylesConfig = {
  container: (base) => ({ ...base }),
  control: (base) => ({
    ...base,
    border: '1px solid #363636',
    padding: '12px 16px',
    background: '#1f1f1f',
    boxShadow: 'none',
    borderRadius: 6,
    gap: 12,
    '&:hover': {
      boxShadow: 'none'
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    '&.sm': {
      fontSize: '14px'
    }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'all 0.2s ease-in-out',
    color: '#4b465c66',
    margin: 0,
    padding: 0
  }),
  input: (base) => ({
    ...base,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#fff',
    padding: 0,
    margin: 0
  }),
  singleValue: (base) => ({
    ...base,
    color: '#DDD',
    fontSize: '14px',
    lineHeight: '20px',
    margin: 0
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 6,
    overflow: 'hidden',
    background: '#1c1a1b',
    border: '1px solid #363636',
    zIndex: 20,
    maxHeight: '242px',
    padding: 8
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  }),
  option: (provided, state) => ({
    ...provided,
    marginBottom: 4,
    fontSize: '15px',
    lineHeight: '22px',
    color: state.isDisabled ? '#55555566' : '#fff',
    background: state.isSelected ? '#ffaa11' : 'transparent',
    cursor: 'pointer',
    borderRadius: 6,
    '&:hover': {
      background: state.isSelected ? '#ffaa11' : 'rgb(255, 170, 17, 0.08)',
      color: state.isSelected ? '#fff' : '#ffaa11'
    },
    '&:last-child': {
      marginBottom: 0
    }
  })
};

export default reactSelectStylesConfig;
