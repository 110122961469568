import { useState, useEffect } from 'react';
import Link from 'next/link';
import Cookies from 'js-cookie';
import { MIGRATION_REMIND_COOKIE, MIGRATION_ACCEPT_COOKIE, useDomainMigration } from 'hooks/useDomainMigration';
import { Modal } from './Modal';
import { isCrushAppMobile } from 'utils/userAgentCheck';

export const DomainMigrationModal = () => {
  const { showMigrationModal, closeMigrationModal, targetDomain, migrateToNewDomain } = useDomainMigration();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const hasAcceptedRedirect = Cookies.get(MIGRATION_ACCEPT_COOKIE);
    if (hasAcceptedRedirect && showMigrationModal) {
      setIsLoading(true);
      const timer = setTimeout(async () => {
        await migrateToNewDomain(targetDomain);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showMigrationModal, targetDomain, migrateToNewDomain]);

  if (!showMigrationModal) return null;
  if (isCrushAppMobile()) return null;

  const handleRemindLater = () => {
    Cookies.set(MIGRATION_REMIND_COOKIE, 'reminded', { expires: 1 });
    closeMigrationModal();
  };

  const handleMigrate = async () => {
    setIsLoading(true);
    Cookies.set(MIGRATION_ACCEPT_COOKIE, 'true', { expires: 365 });
    await migrateToNewDomain(targetDomain);
  };

  return (
    <Modal nonDismissable>
      <div className="text-center flex flex-col gap-4">
        <h2 className="text-2xl text-brand font-semibold">We&apos;ve rebranded to <u>Krush.chat</u> ❤️</h2>
        <p className="text-md text-white">
          Now with a K. Some might call it kinky.
        </p>
        <div className="flex flex-col gap-3 mb-4">
          <button
            className="btn outlined secondary"
            onClick={handleMigrate}
            disabled={isLoading}
          >
            {isLoading ? 'Redirecting...' : `Go to ${new URL(targetDomain).hostname}`}
          </button>
          {/* <button
            className="contained text-sm text-stone-500 hover:underline"
            onClick={handleRemindLater}
            disabled={isLoading}
          >
            Later
          </button> */}
        </div>
      </div>
    </Modal>
  );
};