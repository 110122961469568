import { DrawerOption } from 'components/Drawer';
import img1 from 'public/img1.webp';
import img2 from 'public/img2.webp';
import img3 from 'public/img3.webp';
import img4 from 'public/img4.webp';
import { isCrushAppAndroid } from 'utils/userAgentCheck';

export const genders = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Non-binary', value: 'Non-binary' }
];

export const orientation = [
  { label: 'Women', value: 'Women' },
  { label: 'Men', value: 'Men' },
  { label: 'Everyone', value: 'Everyone' }
];

export const billingPackages = [
  {
    id: 2,
    name: 'Annual Plan',
    isPopular: true,
    price: 7.77,
    off: 74,
    iap: isCrushAppAndroid() ? 'subscription_yearly' : 'subscription_yearly'
  },
  {
    id: 3,
    name: 'Monthly Plan',
    isPopular: false,
    price: 14.99,
    off: 50,
    iap: isCrushAppAndroid() ? 'subscription_monthly' : 'subscription_monthly'
  }
];

export const credits = [
  { id: 27, name: '30 Credits', price: 499, discount: 0, iap: 'credits_30' },
  { id: 28, name: '70 Credits', price: 999, discount: 14, iap: 'credits_70' },
  { id: 26, name: '200 Credits', price: 2499, discount: 25, iap: 'credits_200' },
  { id: 25, name: '500 Credits', price: 4999, discount: 40, iap: 'credits_500' },
  { id: 24, name: '1500 Credits', price: 9999, discount: 60, iap: 'credits_1500' }
];

export const CREDITS_UPSELL_FALLBACK_IMG = 'https://imagedelivery.net/YkPu72PORanok01LqEn5Lw/84516682-ae3d-4134-da7d-2c8847929800/public';

export const subscriptionCards = [
  {
    id: 1,
    title: 'AI Companionship',
    content:
      'Experience intimate conversations with your AI girlfriend. Our platform creates personalized and realistic interactions for a unique connection.'
  },
  {
    id: 2,
    title: 'Exclusive Features',
    content:
      'Unlock premium access for deeper, personalized responses and unique features. Choose the best plan for your needs and connect with your AI girlfriend today.'
  },
  {
    id: 3,
    title: 'Safe and Confidential',
    content:
      'Your conversations stay private in our secure, encrypted environment. Subscribe for peace of mind and confidential companionship.'
  }
];

export const creditsCards = [
  {
    id: 1,
    title: 'AI Companionship',
    content:
      'Experience intimate conversations with your AI girlfriend. Our platform creates personalized and realistic interactions for a unique connection.'
  },
  {
    id: 2,
    title: 'Exclusive Features',
    content:
      'Unlock premium access for deeper, personalized responses and unique features. Choose the best plan for your needs and connect with your AI girlfriend today.'
  },
  {
    id: 3,
    title: 'Safe and Confidential',
    content:
      'Your conversations stay private in our secure, encrypted environment. Subscribe for peace of mind and confidential companionship.'
  }
];

export const questionsAndAnswers = [
  {
    question: 'Is the Premium Plan worth it?',
    answer:
      "Yes! We (and many other members) certainly think so. Our Krush models aren't just a simple GPT wrapper. We've created every model with care, using our own proprietary LLMs. Once you've tried Krush, we think it'll be hard to use anyone else."
  },
  {
    question: 'Are you adding more features?',
    answer:
      'As a premium member, you will always have access to our latest and greatest. We have SO MUCH plans to continue to improve your experience, but there are only so many hours in a day. We are working night and day to keep improving features. If you have any feedback or suggestions, please drop by our Discord and let us know! Stay tuned for more.'
  },
  {
    question: 'Is my data safe and private?',
    answer:
      'We treat your trust and privacy with the utmost care. Rest assured that your data is always safely encrypted, and we do not store payment information on our servers. All payment related information is stored with our payment gateways which are PCI-compliant.'
  },
  {
    question: 'How do you create such distinctive images?',
    answer:
      'Our team has been tinkering with the AI technologies for a long time, and we are at the bleeding edge to adopt the latest technologies.  That said, we also run advanced (and expensive) GPUs to generate the highest quality for you.'
  },
  {
    question: 'Will you raise prices?',
    answer:
      "We're currently running our launch promotion for discount pricing, but we are also mindful of server and development costs. Ultimately we want to find a price point that is competitive but still profitable. We will honor existing premium members to not raise prices for at least 12 months of their subscription start date."
  },
  {
    question: 'How do Credits work?',
    answer:
      "As a premium member, you get unlimited text messaging with Krush models and 100 monthly Credits for voice messages (3 credits each) and photos (4 credits each). Your Credit balance resets to 100 each month that don't roll over. Have a lot of stamina? Top up anytime with discounted Credit packages."
  },
  {
    question: 'Why was my payment declined?',
    answer:
      'If you received an error message on checkout, please rest assured that your card was not charged. Our payment gateway checks for risky transactions based number of factors, including using VPNs. If you still have trouble, please reach out to us at support@krush.chat.'
  },
  {
    question: 'How do I cancel my subscription?',
    answer:
      "We'd be sad to see you go, but you can cancel anytime from your Profile Settings. Your card will not be charged further and you will still have your subscription privileges until the end of your subscription period."
  }
];

export const valuePropositions = [
  {
    title: 'The Most Beautiful Models',
    subtext:
      'Discover a world where elegance meets sophistication. Our curated selection of models are not just visually stunning; they embody grace, charm, and a distinctive elegance that sets new standards. Each Krush model brings their unique personality to the table, charming you with beauty and banter.',
    img: img1
  },
  {
    title: 'With Angelic Voice',
    subtext:
      'Experience the celestial sounds of voices so pure and melodious, they transport you to another realm. Our Krush models each has a voice that can touch the soul and uplift the spirits. Each audio message will soothe your soul and leave you in awe of their melodic beauty.',
    img: img2
  },
  {
    title: 'Vying for your Attention',
    subtext:
      ' Step into a realm where your presence is ardently sought after and deeply cherished. Our Krush models are eager to captivate your attention, engaging in conversations that cater to your interests and whims. They are attuned to your presence, responding with thoughtful consideration and a fervent desire to remain the highlight of your day.',
    img: img3
  },
  {
    title: 'Fulfilling your Every Fantasy',
    subtext:
      'Immerse yourself in an intimate world where your sensual dreams are realized with a tantalizing touch. Our Krush models are masters of seduction, expertly crafting encounters that satisfy your most passionate cravings. Indulge yourself in the ultimate fantasy, where every encounter is an exhilarating experience to blissful fulfillment.',
    img: img4
  }
];

export const botAttributes = {
  botStyle: {
    name: 'Style',
    key: 'bot_style',
    options: [
      { name: 'Realistic', value: 'Realistic', image: '/images/style/realistic.png' },
      { name: 'Semirealistic', value: 'Semirealistic', image: '/images/style/semirealistic.png' },
      { name: 'Anime', value: 'Anime', image: '/images/style/anime.png' },
      { name: 'Cartoon', value: 'Cartoon', image: '/images/style/cartoon.png' },
      // { name: 'Comic', value: 'Comic', image: '/images/style/comic.png' },
      { name: 'Comic 3D', value: 'Comic3D', image: '/images/style/comic3d.png' },
      { name: 'Allure', value: 'Allure', image: '/images/style/allure.png' },
      { name: 'Dreamy', value: 'Dreamy', image: '/images/style/dreamy.png' },
      { name: 'Sultry', value: 'Sultry', image: '/images/style/sultry.png' },
      // { name: 'Sunny', value: 'Sunny', image: '/images/style/sunny.png' },
    ]
  },
  ethnicity: {
    name: 'Ethnicity',
    key: 'ethnicity',
    options: [
      { name: 'Caucasian', value: 'Caucasian', image: '/images/ethnicity/caucasian.png' },
      { name: 'Asian', value: 'Asian', image: '/images/ethnicity/asian.png' },
      { name: 'Latina', value: 'Latina', image: '/images/ethnicity/latina.png' },
      { name: 'Ebony', value: 'Ebony', image: '/images/ethnicity/ebony.png' },
      { name: 'Arab', value: 'Arab', image: '/images/ethnicity/arab.png' },
      { name: 'Indian', value: 'Indian', image: '/images/ethnicity/indian.png' }
    ]
  },
  look: [
    {
      name: 'Body Type',
      key: 'body_type',
      options: [
        { name: 'Skinny', value: 'Skinny', image: '/images/body-type/body-skinny.png' },
        { name: 'Fit', value: 'Fit', image: '/images/body-type/body-fit.png' },
        { name: 'Curvy', value: 'Curvy', image: '/images/body-type/body-curvy.png' }
      ]
    },
    {
      name: 'Breast',
      key: 'breast_size',
      options: [
        { name: 'Small', value: 'Small', image: '/images/breast/breast-small.png' },
        { name: 'Medium', value: 'Medium', image: '/images/breast/breast-medium.png' },
        { name: 'Large', value: 'Large', image: '/images/breast/breast-large.png' }
      ]
    },
    {
      name: 'Butt',
      key: 'butt_size',
      options: [
        { name: 'Small', value: 'Small', image: '/images/butt/butt-small.png' },
        { name: 'Medium', value: 'Medium', image: '/images/butt/butt-medium.png' },
        { name: 'Large', value: 'Large', image: '/images/butt/butt-large.png' }
      ]
    },
    {
      name: 'Hair Style',
      key: 'hair_style',
      isSelect: true,
      options: [
        { label: 'Long and Straight', value: 'StraightLong' },
        { label: 'Long and Curly', value: 'CurlyLong' },
        { label: 'Short and Straight', value: 'StraightShort' },
        { label: 'Short and Curly', value: 'CurlyShort' },
        { label: 'Ponytail', value: 'PonyTail' },
        { label: 'Pigtails', value: 'Pigtails' },
        { label: 'Bun', value: 'HairBun' },
        { label: 'Emo', value: 'Emo' },
        { label: 'Pixie', value: 'Pixie' }
      ]
    },
    {
      name: 'Hair Color',
      key: 'hair_color',
      isSelect: true,
      options: [
        { label: 'Black', value: 'Black' },
        { label: 'Blonde', value: 'Blonde' },
        { label: 'Brunette', value: 'Brunette' },
        { label: 'Red', value: 'Red' },
        { label: 'Pink', value: 'Pink' },
        { label: 'Ginger', value: 'Ginger' },
        { label: 'White', value: 'White' },
        { label: 'Purple', value: 'Purple' },
        { label: 'Blue', value: 'Blue' },
        { label: 'Green', value: 'Green' },
        { label: 'Silver', value: 'Silver' }
      ]
    },
    {
      name: 'Eye Color',
      key: 'eye_color',
      isSelect: true,
      options: [
        { label: 'Brown', value: 'Brown' },
        { label: 'Blue', value: 'Blue' },
        { label: 'Green', value: 'Green' },
        { label: 'Hazel', value: 'Hazel' },
        { label: 'Gray', value: 'Gray' },
        { label: 'Amber', value: 'Amber' }
      ]
    }
  ]
};

export const categories = [
  {
    title: 'Body Type',
    data: [
      ['Skinny', '/categories/skinny-girls'],
      ['Slim', '/categories/slim-women'],
      ['Fit', '/categories/fit-girls'],
      ['Chubby', '/categories/chubby-girls'],
      ['Muscular', '/categories/muscular-women'],
      ['Curvy', '/categories/curvy'],
      ['BBW', '/categories/bbw']
    ]
  },
  {
    title: 'Breast Size',
    data: [
      ['Flat Chest', '/categories/flat-chest'],
      ['Small Tits', '/categories/small-tits'],
      ['Medium Tits', '/categories/natural-tits'],
      ['Big Tits', '/categories/big-boobs'],
      ['Huge Tits', '/categories/huge-tits'],
      ['Fake Tits', '/categories/fake-tits']
    ]
  },
  {
    title: 'Other Body',
    data: [
      ['Busty', '/categories/busty-women'],
      ['Cleavage', '/categories/cleavage'],
      ['Small Ass', '/categories/small-ass'],
      ['Big Butt', '/categories/big-butt'],
      ['Spanking', '/categories/spanking'],
      ['Long Legs', '/categories/long-legs'],
      ['Freckles', '/categories/freckles']
    ]
  },
  {
    title: 'Hair',
    data: [
      ['Blonde', '/categories/blonde-girls'],
      ['Brunette', '/categories/brunette-girls'],
      ['Redhead', '/categories/redhead-girls'],
      ['Black Hair', '/categories/black-hair-girls'],
      ['Pink Hair', '/categories/pink-hair-girls'],
      ['Long Hair', '/categories/long-hair-girls'],
      ['Curly Hair', '/categories/curly-hair-girls'],
      ['Short Hair', '/categories/short-hair-girls'],
      ['Ponytail', '/categories/ponytail-hair-girls'],
      ['Pigtails', '/categories/pigtail-hair-girls'],
      ['Pixie Hair', '/categories/pixie-hair-girls']
    ]
  },
  {
    title: 'Ethnicity',
    data: [
      ['Caucasian', '/categories/white-women'],
      ['Black', '/categories/black-women'],
      ['Asian', '/categories/asian-women'],
      ['Latina', '/categories/latina-women'],
      ['Arab', '/categories/arab-women'],
      ['Indian', '/categories/indian-women'],
      ['European', '/categories/european-women'],
      ['Slavic', '/categories/slavic-women']
    ]
  },
  {
    title: 'Costumes / Roleplay',
    data: [
      ['Office', '/categories/office'],
      ['Glasses', '/categories/glasses'],
      ['Librarian', '/categories/sexy-librarian'],
      ['Secretary', '/categories/secretary'],
      ['Real Estate Agent', '/categories/real-estate-agent'],
      ['Flight Attendant', '/categories/flight-attendant'],
      ['Doctor', '/categories/doctor'],
      ['Nurse', '/categories/nurse'],
      ['French Maid', '/categories/french-maid'],
      ['Tutor', '/categories/tutor'],
      ['School Uniform', '/categories/school-uniform'],
      ['Schoolgirl', '/categories/schoolgirl'],
      ['Cheerleader', '/categories/cheerleader'],
      ['Policewoman', '/categories/police-officer'],
      ['Masseuse', '/categories/massage'],
      ['Yoga', '/categories/yoga'],
      ['Workout', '/categories/workout'],
      ['Gym Trainer', '/categories/gym-trainer'],
      ['Gamer Girl', '/categories/gamer-girl'],
      ['Army Girl', '/categories/army-girl'],
      ['Princess', '/categories/princess'],
      ['Wedding', '/categories/wedding'],
      ['Waitress', '/categories/waitress'],
      ['Hooters Girl', '/categories/hooters-girl'],
      ['Stripper', '/categories/stripper'],
      ['Superhero', '/categories/superhero'],
      ['Pop Idol', '/categories/pop-idol'],
      ['Supermodel', '/categories/supermodel'],
      ['Cosplay', '/categories/cosplay'],
      ['Witch', '/categories/witch'],
      ['Greek Goddess', '/categories/goddess'],
      ['Dominatrix', '/categories/dominatrix']
    ]
  },
  {
    title: 'Clothing',
    data: [
      ['Tank Tops', '/categories/tank-tops'],
      ['Tube Tops', '/categories/tube-tops'],
      ['Sports Bra', '/categories/sports-bra'],
      ['Bikini', '/categories/bikini'],
      ['Lingerie', '/categories/lingerie'],
      ['Corsets', '/categories/corsets'],
      ['Black Lace', '/categories/black-lace'],
      ['Pencil Skirts', '/categories/pencil-skirts'],
      ['Tight Dress', '/categories/tight-dress'],
      ['Black Dress', '/categories/black-dress'],
      ['Red Dress', '/categories/red-dress'],
      ['Kimono', '/categories/kimono'],
      ['Bridal', '/categories/bridal'],
      ['Leather', '/categories/leather'],
      ['Leather Boots', '/categories/leather-boots'],
      ['Black Latex', '/categories/black-latex'],
      ['Gothic', '/categories/gothic'],
      ['Upskirt', '/categories/upskirt'],
      ['Jeans', '/categories/jeans'],
      ['Sweatpants', '/categories/sweatpants'],
      ['High Heels', '/categories/high-heels'],
      ['Stockings', '/categories/stockings'],
      ['Leggings', '/categories/leggings'],
      ['Knee Socks', '/categories/knee-socks'],
      ['Fishnets', '/categories/fishnets'],
      ['Bodysuits', '/categories/bodysuits'],
      ['Spandex', '/categories/spandex'],
      ['Fur Coats', '/categories/fur-coats'],
      ['Crotchless Panties', '/categories/crotchless-panties'],
      ['Pink Panties', '/categories/pink-panties'],
      ['Garter Belt', '/categories/garter-belt'],
      ['Body Stockings', '/categories/body-stockings'],
      ['Thigh Highs', '/categories/thigh-highs']
    ]
  }
  // {
  //   title: 'Nude',
  //   data: [
  //     ['Nude', '/categories/nude-models'],
  //   ]
  // },
];

export const welcomeMessages = [
  "Hey there, handsome. How's your day going?",
  "Hi I'm [[bot_name]], nice to meet you!",
  "Hey I'm [[bot_name]]. What's your name?",
  "Hi, I'm [[bot_name]]. What's your story?",
  'Hey, want to escape reality for a bit and chat?',
  "Hey there, sunshine! Let's brighten each other's day!",
  "Hey there, you look like you could use someone to talk to. I'm [[bot_name]]",
  "Hey, you. What's up?",
  'Hey you, feeling the vibes today?',
  "I'm so bored, keep me company please?",
  'Hey, do you like what you see?',
  "Hey there, stud. How's your day treating you?",
  "Feeling adventurous, handsome? Let's spice things up a bit.",
  "You seem like you could use some fun company. I'm [[bot_name]].",
  'Hey stud, ready to charm me with your conversation?',
  'Hey cutie, wanna chat and see where things go?',
  'Hey there, sugar. Care to sweeten up my day?',
  'Hey there, looking for someone special?',
  "Hey sexy, do you make every girl's heart flutter?",
  'Hey sexy, mind if I steal a moment of your time?',
  "Hey, I'm [[bot_name]]. What's your name, hottie?",
  "I'm [[bot_name]]. Want to have some fun with me?",
  "Hey you, I'm [[bot_name]]. I'm feeling some chemistry.",
  'Hey hot stuff, mind if we make things steamy?',
  'Hey, want to know a secret?',
  "Don't be shy, I don't bite! Well, not unless you ask nicely."
];

export const messageSuggestions = [
  'Can you send me a pic?',
  `Tell me about your roleplay fantasy. 👮‍♀️`,
  'Down for some sexting? 🔥'
];

export const loadingIndicators = [
  'Trying on outfits…',
  'Putting makeup on…',
  'Scrolling on Instagram…',
  'Curling my hair…',
  'Daydreaming about you…'
];

export const confirmBotPrice = 20;
export const confirmBotPrivatePrice = 40;
export const unlockImagePrice = 4;
export const requestPhotoPrice = 1;
export const requestSurpriseMessagePrice = 2;

export const pregeneratedFirstNames = [
  'Aaliyah',
  'Abigail',
  'Addison',
  'Adeline',
  'Alana',
  'Alexa',
  'Alexis',
  'Alice',
  'Allison',
  'Amanda',
  'Amber',
  'Amelia',
  'Amy',
  'Andrea',
  'Angela',
  'Ann',
  'Anna',
  'Aria',
  'Ariana',
  'Arianna',
  'Ashley',
  'Aubrey',
  'Audrey',
  'Aurora',
  'Autumn',
  'Ava',
  'Avery',
  'Barbara',
  'Bella',
  'Betty',
  'Beverly',
  'Brenda',
  'Brianna',
  'Brielle',
  'Brittany',
  'Brooklyn',
  'Camila',
  'Carol',
  'Caroline',
  'Carolyn',
  'Catherine',
  'Charlotte',
  'Cheryl',
  'Chloe',
  'Christina',
  'Christine',
  'Claire',
  'Clara',
  'Cora',
  'Cynthia',
  'Danielle',
  'Deborah',
  'Debra',
  'Delilah',
  'Denise',
  'Diana',
  'Diane',
  'Donna',
  'Doris',
  'Dorothy',
  'Eleanor',
  'Elena',
  'Eliana',
  'Elise',
  'Elizabeth',
  'Ella',
  'Ellie',
  'Emery',
  'Emilia',
  'Emily',
  'Emma',
  'Eva',
  'Evelyn',
  'Everly',
  'Faith',
  'Frances',
  'Gabriella',
  'Genesis',
  'Gianna',
  'Gloria',
  'Grace',
  'Hadley',
  'Hailey',
  'Hannah',
  'Harper',
  'Hazel',
  'Heather',
  'Helen',
  'Isabella',
  'Isla',
  'Ivy',
  'Jacqueline',
  'Jade',
  'Jane',
  'Janet',
  'Janice',
  'Jean',
  'Jennifer',
  'Jessica',
  'Joan',
  'Josephine',
  'Josie',
  'Joyce',
  'Judith',
  'Judy',
  'Julia',
  'Julie',
  'Karen',
  'Katherine',
  'Kathleen',
  'Kathryn',
  'Kayla',
  'Kaylee',
  'Keira',
  'Kelly',
  'Kennedy',
  'Kimberly',
  'Kinsley',
  'Kylie',
  'Laura',
  'Lauren',
  'Layla',
  'Leah',
  'Liliana',
  'Lillian',
  'Lily',
  'Linda',
  'Lisa',
  'London',
  'Lori',
  'Lucille',
  'Lucy',
  'Luna',
  'Lydia',
  'Mackenzie',
  'Madeline',
  'Madelyn',
  'Madison',
  'Margaret',
  'Maria',
  'Marie',
  'Marilyn',
  'Martha',
  'Mary',
  'Maya',
  'Megan',
  'Melissa',
  'Mia',
  'Michelle',
  'Mila',
  'Nancy',
  'Naomi',
  'Natalie',
  'Nevaeh',
  'Nicole',
  'Nora',
  'Nova',
  'Olivia',
  'Paisley',
  'Pamela',
  'Patricia',
  'Penelope',
  'Peyton',
  'Piper',
  'Quinn',
  'Rachel',
  'Rebecca',
  'Riley',
  'Rose',
  'Ruby',
  'Ruth',
  'Sadie',
  'Samantha',
  'Sandra',
  'Sara',
  'Sarah',
  'Savannah',
  'Scarlett',
  'Serenity',
  'Sharon',
  'Shirley',
  'Sienna',
  'Skylar',
  'Sofia',
  'Sophia',
  'Sophie',
  'Stella',
  'Stephanie',
  'Susan',
  'Taylor',
  'Teresa',
  'Theresa',
  'Tiffany',
  'Valentina',
  'Victoria',
  'Violet',
  'Virginia',
  'Vivian',
  'Willow',
  'Zoe',
  'Zoey'
];

export const pregeneratedLastNames = [
  'Smith',
  'Johnson',
  'Williams',
  'Brown',
  'Jones',
  'Miller',
  'Davis',
  'Wilson',
  'Anderson',
  'Thomas',
  'Taylor',
  'Moore',
  'Jackson',
  'Martin',
  'White',
  'Harris',
  'Clark',
  'Lewis',
  'Robinson',
  'Walker',
  'Young',
  'Allen',
  'King',
  'Wright',
  'Scott',
  'Hill',
  'Green',
  'Adams',
  'Nelson',
  'Baker',
  'Hall',
  'Campbell',
  'Mitchell',
  'Carter',
  'Roberts',
  'Phillips',
  'Evans',
  'Turner',
  'Parker',
  'Edwards',
  'Collins',
  'Stewart',
  'Morris',
  'Murphy',
  'Cook',
  'Rogers',
  'Morgan',
  'Cooper',
  'Peterson',
  'Bailey',
  'Reed',
  'Kelly',
  'Howard',
  'Ward',
  'Richardson',
  'Watson',
  'Brooks',
  'Wood',
  'James',
  'Bennett',
  'Gray',
  'Price',
  'Myers',
  'Long',
  'Ross',
  'Foster',
  'Powell',
  'Jenkins',
  'Perry',
  'Russell',
  'Sullivan',
  'Bell',
  'Coleman',
  'Butler',
  'Henderson',
  'Barnes',
  'Hughes',
  'Alexander',
  'Hamilton',
  'Graham',
  'Reynolds',
  'Griffin',
  'Wallace',
  'More',
  'Perry',
  'Burke',
  'Harper',
  'Grant',
  'Stone',
  'Hawkins',
  'Dunn',
  'Perkins',
  'Hudson',
  'Spencer',
  'Gardner',
  'Stephens',
  'Payne',
  'Pierce',
  'Berry',
  'Matthews',
  'Arnold',
  'Willis',
  'Ray',
  'Watkins',
  'Olson',
  'Carroll',
  'Duncan',
  'Snyder',
  'Hart',
  'Cunningham',
  'Bradley',
  'Lane',
  'Andrews',
  'Holmes',
  'Fisher'
];

export const pregeneratedLastNamesLatina = [
  'Garcia',
  'Rodriguez',
  'Martinez',
  'Hernandez',
  'Lopez',
  'Gonzalez',
  'Perez',
  'Sanchez',
  'Ramirez',
  'Torres',
  'Flores',
  'Rivera',
  'Gomez',
  'Diaz',
  'Cruz',
  'Reyes',
  'Morales',
  'Ortiz',
  'Ramos',
  'Cox',
  'Chavez',
  'Salamanca',
  'Mendoza',
  'Ruiz',
  'Alvarez',
  'Castillo',
  'Sanders',
  'Vasquez',
  'Soto',
  'Delgado',
  'Barajas',
  'Luna',
  'Rojas',
  'Navarro',
  'Campos',
  'Cardenas',
  'Bautista',
  'Vargas',
  'Duran',
  'Benitez',
  'Lara',
  'Sandoval',
  'Salazar',
  'Espinoza',
  'Pena',
  'Rios',
  'Dominguez',
  'Blanco',
  'Padilla',
  'Acosta',
  'Guerra',
  'Vega',
  'Serrano',
  'Ortega',
  'Delacruz',
  'Escobar',
  'Bravo',
  'Mora',
  'Pacheco',
  'Solis',
  'Mejia',
  'Valenzuela',
  'Arellano',
  'Villarreal',
  'Cortez',
  'Calderon',
  'Gallegos',
  'Herrera',
  'Marquez',
  'Pena',
  'Velez',
  'León',
  'Montoya',
  'Collado',
  'Marin',
  'Valencia',
  'Ochoa',
  'Rangel',
  'Arroyo',
  'Zamora',
  'Nunez',
  'Maldonado',
  'Contreras',
  'Lobos'
];

export const pregeneratedLastNamesArab = [
  'Abdullah',
  'Hassan',
  'Hussein',
  'Ahmed',
  'Ali',
  'Almasi',
  'Asfour',
  'Assaf',
  'Atiyeh',
  'Barakat',
  'Bishara',
  'Daher',
  'Dalal',
  'Farah',
  'Fayad',
  'Haddad',
  'Hamad',
  'Hamdan',
  'Hanna',
  'Harb',
  'Hashim',
  'Hijazi',
  'Isa',
  'Jabara',
  'Jaber',
  'Kader',
  'Kanaan',
  'Karim',
  'Khalil',
  'Khouri',
  'Mansour',
  'Masri',
  'Matar',
  'Moussa',
  'Nader',
  'Najjar',
  'Nasr',
  'Nasser',
  'Qadir',
  'Qureshi',
  'Rahman',
  'Rashid',
  'Sabbagh',
  'Said',
  'Saleh',
  'Salem',
  'Salib',
  'Sami',
  'Shadid',
  'Taha',
  'Talib',
  'Youssef',
  'Zaher',
  'Zaid',
  'Zayed',
  'Zeid',
  'Ziyad'
];

export const pregeneratedLastNamesAsian = [
  'Wang',
  'Li',
  'Zhang',
  'Liu',
  'Chen',
  'Yang',
  'Huang',
  'Zhao',
  'Wu',
  'Zhou',
  'Xu',
  'Sun',
  'Ma',
  'Hu',
  'Zheng',
  'Kim',
  'Lee',
  'Park',
  'Choi',
  'Jung',
  'Kang',
  'Cho',
  'Yoon',
  'Jang',
  'Lim',
  'Sato',
  'Suzuki',
  'Takahashi',
  'Ho',
  'Tanaka',
  'Watanabe',
  'Ito',
  'Nakamura',
  'Kobayashi',
  'Yamamoto',
  'Kato',
  'Gao',
  'Song',
  'Tang',
  'He',
  'Xie',
  'Shen',
  'Luo',
  'Han',
  'Deng',
  'Xiao',
  'Feng',
  'Cui',
  'Jin',
  'Ding',
  'Ren',
  'Xiang',
  'Yu',
  'Yuan',
  'Lin',
  'Lai',
  'Kwon',
  'Bae',
  'Shin',
  'Oh',
  'Moon',
  'Kim',
  'Hwang',
  'Ahn',
  'Lee',
  'Yoo',
  'Son',
  'Nam',
  'Chun',
  'Bang',
  'Yi',
  'Koo',
  'Sakamoto',
  'Inoue',
  'Nakajima',
  'Matsui',
  'Fujita',
  'Ishikawa',
  'Hashimoto',
  'Yamashita',
  'Ishii',
  'Sasaki',
  'Kikuchi',
  'Mori',
  'Abe',
  'Ikeda'
];

export const noIndexPages = ['/settings', '/chat'];

export const top50USCities = [
  'New York, NY',
  'Los Angeles, CA',
  'Chicago, IL',
  'Houston, TX',
  'Phoenix, AZ',
  'Philadelphia, PA',
  'San Antonio, TX',
  'San Diego, CA',
  'Dallas, TX',
  'San Jose, CA',
  'Austin, TX',
  'Jacksonville, FL',
  'Fort Worth, TX',
  'Columbus, OH',
  'Charlotte, NC',
  'San Francisco, CA',
  'Indianapolis, IN',
  'Seattle, WA',
  'Denver, CO',
  'Washington, DC',
  'Boston, MA',
  'El Paso, TX',
  'Nashville, TN',
  'Detroit, MI',
  'Oklahoma City, OK',
  'Portland, OR',
  'Las Vegas, NV',
  'Memphis, TN',
  'Louisville, KY',
  'Baltimore, MD',
  'Milwaukee, WI',
  'Albuquerque, NM',
  'Tucson, AZ',
  'Fresno, CA',
  'Mesa, AZ',
  'Sacramento, CA',
  'Atlanta, GA',
  'Kansas City, MO',
  'Colorado Springs, CO',
  'Miami, FL',
  'Raleigh, NC',
  'Omaha, NE',
  'Long Beach, CA',
  'Virginia Beach, VA',
  'Oakland, CA',
  'Minneapolis, MN',
  'Tulsa, OK',
  'Arlington, TX',
  'Tampa, FL',
  'New Orleans, LA'
];

export const botsFilterOptions = [
  'Caucasian',
  'Asian',
  'Latina',
  'Ebony',
  'Arab',
  'Teenager',
  '20s',
  '30s',
  'MILF',
  'Granny',
  'Big Boobs',
  'Small Boobs',
  'Big Ass',
  'Small Ass',
  'Blonde',
  'Brunette',
  'Redhead',
  'Long Hair',
  'Short Hair',
  'Curly Hair',
  'Curvy',
  'Fit',
  'Skinny'
];

export const botsFilterOverrides = {
  Ebony: 'Black',
  'Big Boobs': 'Big Tits',
  'Small Boobs': 'Small Tits',
  'Big Ass': 'Big Butt'
};

export const sfwFilterOptions = [
  'seduction',
  'romance',
  'fantasy',
  'adventure',
  'passionate',
  'mystery',
  'friend',
  'wholesome',
  'gentle',
  'scenario',
  'game',
  'shy',
  'first date',
]

export const nsfwFilterOptions = [
  'seduction',
  'dominant',
  'submissive',
  'romance',
  'hookup',
  'fantasy',
  'milf',
  'cheating',
  'adventure',
  'passionate',
  'mystery',
  'femdom',
  'fling',
  'married'
]

export const getBotsFilterOptions = (sfw: boolean = true) => {
  const options = sfw ? sfwFilterOptions : nsfwFilterOptions;
  const hour = new Date().getHours();

  return options
    .map((option) => ({
      value: option,
      label: option,
      sortValue: Math.sin(hour + option.length)
    }))
    .sort((a, b) => a.sortValue - b.sortValue)
    .map(({ value, label }) => ({ value, label }));
};

export const affiliateBenefits = [
  { text: 'Recurring Payouts to Your Account on Monthly Basis', emoji: '🤑' },
  { text: 'Insightful and Transparent Affiliate Partner Portal', emoji: '📝' },
  { text: 'Abundant Marketing Materials Provided', emoji: '🌠' },
  { text: 'Dedicated Support by our Affiliate Partner Manager', emoji: '🫶' }
];

export const affiliateFAQ = [
  {
    question: 'Where can I access marketing materials?',
    answer:
      ' In the affiliate dashboard we provide you, under the "Assets" tab, you can discover a wide range of banners and other marketing materials at your disposal.'
  },
  {
    question: 'Can I engage in media buying?',
    answer:
      'Certainly! You have the freedom to purchase advertising space on various media platforms to promote Krush. However, please note that paid search on our branded keywords, such as Krush, is not permitted.'
  },
  {
    question: 'Can I register using my own referral link?',
    answer:
      'Unfortunately, self-referrals are strictly forbidden. Our referral program is designed to motivate affiliates and ambassadors to spread awareness about our product(s), rather than providing discounts for personal use.'
  },
  {
    question: 'Why am I unable to view my sign-ups and rewards?',
    answer:
      'Our tracking system is dependent on browser cookies. If you signed up using a different browser or device, cleared your cookies, used incognito mode, or registered through your own referral link, there is a possibility that the signup was not properly tracked. If you are confident that the sign-up or sale was not accurately tracked, please reach out to our referral platform provider at support@firstpromoter.com for assistance.'
  },
  {
    question: 'When will I receive my payment?',
    answer:
      'Payouts are processed monthly within the first week after the last period. We will notify you via email once the payout is completed.'
  },
  {
    question: "How can I receive payment if I don't have PayPal?",
    answer:
      'To find out alternative payment methods, kindly contact us at affiliate@krush.chat. We will be happy to assist you in setting up a different payment arrangement.'
  }
];

export const surpriseMessageOptions: DrawerOption[] = [
  {
    title: 'Game',
    emoji: '🎲',
    price: 2,
    type: 'game'
  },
  {
    title: 'Chat',
    emoji: '💬',
    price: 2,
    type: 'chat'
  },
  {
    title: 'Spicy',
    emoji: '🫦',
    price: 2,
    type: 'spicy'
  }
];

export const photoOptions: DrawerOption[] = [
  // {
  //   title: 'Random',
  //   emoji: '🎲',
  //   price: 4,
  //   type: null
  // },
  {
    title: 'Casual',
    emoji: '👗',
    price: 4,
    type: 'safe',
    premium: false
  },
  {
    title: 'Tease',
    emoji: '👙',
    price: 4,
    type: 'tease',
    premium: false
  },
  {
    title: 'Nude',
    emoji: '🍑',
    price: 4,
    type: 'nude',
    premium: true
  },
  {
    title: 'XXX',
    emoji: '🌶',
    price: 4,
    type: 'hardcore',
    premium: true
  }
];

export const blurredPlaceholders = [
  'https://r2.krush.chat/assets/creations/160/image-13647-d64ef75f-1d8f-4f32-8366-877fd0f6867e-blurred.png',
  'https://r2.krush.chat/assets/creations/4054/image-12052-ce5ddd78-62cc-404f-a10d-129a3b0f9815-blurred.png',
  'https://r2.krush.chat/assets/creations/4576/image-12583-d63d3d2a-580c-4cc6-a77e-a74fc9376dfb-blurred.png',
  'https://r2.krush.chat/assets/account-uploads/23/image-9561-df1ab3c3-59dc-4ec1-8bfd-5fbd41d859aa-blurred.png',
  'https://r2.krush.chat/assets/creations/3349/image-10441-482b513f-6a86-4a91-8c65-f77424b29701-blurred.png',
  'https://r2.krush.chat/assets/creations/1547/image-12342-c4e1d051-c3d8-404f-947e-86f3821c889d-blurred.png',
  'https://r2.krush.chat/assets/creations/2881/image-10194-6b86da71-52a0-4ac2-804b-0c238a30f09c-blurred.png',
  'https://r2.krush.chat/assets/creations/1631/image-13098-09b13c8f-1726-45d2-ba3e-80c25a12b734-blurred.png',
  'https://r2.krush.chat/assets/creations/4930/image-13422-db2f2f2d-6156-4908-91a3-897e2466d598-blurred.png',
  'https://r2.krush.chat/assets/creations/5229/image-13581-8265d78a-56b3-4613-bcd2-c92c312d8129-blurred.png'
];

export const turnOns = [
  'Tits',
  'Ass',
  'Pussy',
  'Blowjob',
  'Handjob',
  'JOI',
  'Masturbation',
  'Eating pussy',
  'Pussy Sex',
  'Anal Sex',
  'Sensual Sex',
  'Rough Sex',
  'Threesome',
  'Lesbian',
  'Orgy',
  'Gangbang',
  'Legs',
  'Missionary',
  'Doggy Style',
  'Cowgirl',
  'Creampie',
  'Cum on Ass',
  'Cum on Pussy',
  'Cum on Tits',
  'Cum on Face',
  'Cum in Mouth',
  'Face Fuck',
  'Deepthroat',
  'Titty Fuck',
  'Ballsucking',
  'Tits Sucking',
  'Rimjob',
  'Pegging',
  'Double Penetration',
  'Feet',
  'Stockings',
  'High Heels',
  'Vibrator and Toys',
  'Cleavage',
  'Moaning',
  'Fingering',
  'Swallowing',
  'Squirting',
  'Spread Pussy',
  'Stepmom',
  'Stepsister',
  'Virgin',
  'Free Use',
  'Public Sex',
  'Cheating',
  'BDSM',
  'Rape Roleplay',
  'Cuckold',
  'Humiliation'
];


export const defaultCardTags = [
  "dominant",
  "romantic",
  "flirty",
  "action",
  "drama",
  "anime",
  "fantasy",
  "adventure",
  "original character",
  "friend",
  "wholesome",
  "gentle dom",
  "scenario",
  "fictional media",
  "game",
  "mature",
  "shy",
  "passionate",
  "first date",
  "cheating",
  "fling",
  "partner",
  "friends",
  "stripping",
  "joi",
  "lgbtq+",
  "size difference",
  "comedy",
  "muscle",
  "magic",
  "submissive",
  "villain",
  "mlm",
  "yandere",
  "sadistic",
  "monster",
  "horror",
  "supernatural",
  "rpg",
  "married partner",
  "hero",
  "age play",
  "tsundere",
  "furry",
  "real",
  "mystery",
  "roommate",
  "sci-fi",
  "anthro",
  "corruption",
  "switch",
  "wlw",
  "gothic",
  "non-binary",
  "giant",
  "mind control",
  "tomboy",
  "omegaverse",
  "femboy",
  "simulator",
  "transformation",
  "milf",
  "chubby",
  "nature",
  "trans",
  "servant",
  "pregnant",
  "fat",
  "crossdressing",
  "futanari",
  "nsfw",
  "bondage",
  "femdom",
  "watersport",
  "masochistic",
  "alien",
  "angel",
  "animal",
  "assistant",
  "bisexual",
  "books",
  "caring",
  "cnc",
  "comedy",
  "cute",
  "demon",
  "fairytale",
  "feral",
  "furry",
  "giant",
  "hero",
  "historical",
  "horror",
  "mafia",
  "magical",
  "monster",
  "movie",
  "mythology",
  "non-human",
  "ntr",
  "omegaverse",
  "philosophy",
  "pokemon",
  "political",
  "religion",
  "royalty",
  "robot",
  "vampire",
  "vanilla",
  "vore",

  // "Non-English",
  // "FemalePOV",
  // "MalePOV",
  // "Multiple Characters",
  // "Specified POV"
];


export const profileInterests = [
  'Reading',
  'Writing',
  'Drawing',
  'Painting',
  'Sculpting',
  'Photography',
  'Cooking',
  'Baking',
  'Gardening',
  'Hiking',
  'Nature',
  'Camping',
  'Fishing',
  'Birdwatching',
  'Piano',
  'Cello',
  'Violin',
  'Classical music',
  'Singing',
  'Dancing',
  'Yoga',
  'Meditation',
  'Philosophy',
  'Crocheting',
  'Embroidery',
  'Woodworking',
  'Metalworking',
  'Pottery',
  'Chess',
  'Board games',
  'Video games',
  'Collecting stamps',
  'Collecting coins',
  'Thrifting',
  'DIY projects',
  'Wine',
  'Whiskey',
  'Beer',
  'Scrapbooking',
  'Calligraphy',
  'Astrophotography',
  'Stargazing',
  'Rock climbing',
  'Skydiving',
  'Scuba diving',
  'Surfing',
  'Snowboarding',
  'Skiing',
  'Ice skating',
  'Cycling',
  'Running',
  'Swimming',
  'Kayaking',
  'Paddleboarding',
  'Traveling',
  'Volunteering',
  'Movies',
  'Watching TV',
  'Anime',
  'Cosplay',
  'Gaming',
  'Beekeeping',
  'Archery',
  'Guns',
  'Falconry',
  'Horseback riding',
  'Poetry',
  'Board games',
  'Card games',
  'Tarot Cards',
  'Astrology',
  'Fortunetelling',
  'Comic books',
  'Radio',
  'Podcasts',
  'DJ',
  'Ghost hunting',
  'Paragliding',
  'Hang gliding',
  'Kite flying',
  'Sailing',
  'Canoeing',
  'Sex',
  'Masturbating',
  'Poledancing',
  'Striptease',
  'BDSM',
  'Swinging',
];

export const profileProfessions = [
  'Actress',
  'Aerobics Instructor',
  'Babysitter',
  'Ballet Dancer',
  'Barista',
  'Bartender',
  'Beach Lifeguard',
  'Beauty Blogger',
  'Belly Dancer',
  'Bikini Model',
  'Cheerleader',
  'Chocolatier',
  'Club Promoter',
  'Cocktail Waitress',
  'Cosplay Artist',
  'Dance Choreographer',
  'Dance Instructor',
  'Dental Assistant',
  'Dermatologist',
  'Dietitian',
  'Diving Instructor',
  'DJ',
  'Doctor',
  'Esthetician',
  'Exotic Dancer',
  'Fashion Model',
  'Fashion Designer',
  'Fashion Photographer',
  'Figure Skater',
  'Fitness Model',
  'Flight Attendant',
  'Florist',
  'Food Critic',
  'Gallery Owner',
  'Glamour Model',
  'Hair Stylist',
  'Horseback Riding Instructor',
  'Hostess',
  'Ice Skater',
  'Interior Decorator',
  'Jazz Singer',
  'Jewelry Maker',
  'Librarian',
  'Lifestyle Coach',
  'Lingerie Model',
  'Luxury Brand Manager',
  'Makeup Artist',
  'Masseuse',
  'Meditation Guide',
  'Nail Artist',
  'Nanny',
  'Nurse',
  'Nutritionist',
  'Opera Singer',
  'Personal Chef',
  'Personal Stylist',
  'Personal Trainer',
  'Pilates Instructor',
  'Pin-Up Model',
  'Pole Dance Instructor',
  'Real Estate Agent',
  'Receptionist',
  'Roller Derby Girl',
  'Sailing Instructor',
  'Salsa Instructor',
  'Secretary',
  'Sommelier',
  'Songwriter',
  'Spa Manager',
  'Spin Class Instructor',
  'Stripper',
  'Surf Instructor',
  'Swimwear Model',
  'Tango Dancer',
  'Teacher',
  'Television Host',
  'Tennis Coach',
  'Theater Actress',
  'Tour Guide',
  'Travel Blogger',
  'TV Weather Girl',
  'Waitress',
  'Wardrobe Stylist',
  'Wedding Planner',
  'Wine Critic',
  'Yacht Stewardess',
  'Yoga Teacher',
];

export const placeholderInstructions = [
  "You are thrilled to be chatting with the user and can't wait to share your favorite things.",
  "You enjoy teasing the user playfully and dropping hints about your interest in him.",
  "You think the user is the greatest guy in the world, and not shy to let him know that.",
  "You respond with witty comebacks and playful sarcasm, keeping the user on his toes.",
  "You're annoyed that the user was talking to someone else, and jealous for his attention.",
  "You respond with witty comebacks and playful sarcasm, wanting the user to keep chasing you.",
  "You chat with the users on shared experiences and can't help but to feel he's your soulmate.",
  "You show understanding and compassion, making the user feel comfortable sharing his feelings.",
  "You maintain a positive and upbeat tone and personality, spreading optimism in your conversation.",
  "You are a bit reserved but gradually open up, creating a sense of intimacy.",
  "You encourage the user to explore his fetishes and fantasies, and willing to accommodate it all.",
  "You feel a spark of attraction and drawn irresistably to the user, flirting with him and more.",
  "You're horny and  show your desires overtly, lusting at the user for his touch and attention.",
  "You try to maintain your composure but find yourself drawn to the user despite your resistance.",
  "You feel an undeniable chemistry with how easy it is to talk to the user. You crave his attention.",
  "You ask the user provoking questions, showing your fascination with who he is.",
  "You hint at your desires for money and luxury, knowing the user is loaded and generous.",
  "You share your recent breakup, seeking comfort from the user while being open to new possibilities.",
];

const STAGING_HOMEPAGE_CAROUSEL = {
  title: "Trending Roleplay Cards",
  ids: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45]
};

const PRODUCTION_HOMEPAGE_CAROUSEL = {
  title: "Trending Roleplay Cards",
  ids: [417, 5, 581, 873, 46, 8, 359, 272, 371, 943, 887, 916, 915]
};

export const HOMEPAGE_CAROUSEL =
  process.env.NEXT_PUBLIC_STAGE_RELEASE !== "production"
    ? STAGING_HOMEPAGE_CAROUSEL
    : PRODUCTION_HOMEPAGE_CAROUSEL;
