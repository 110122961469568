import Link from 'next/link';
import React from 'react';
import { useAuthStore } from 'store/auth';
import SilverCoin from 'public/images/silverCoin.png';
import GoldCoin from 'public/images/goldCoin.png';

export const CoinBalance = () => {
  const { user, isLoading } = useAuthStore();
  const CoinImage = user?.is_premium ? GoldCoin : SilverCoin;

  return !isLoading && (
    <div className="flex flex-wrap items-center justify-center gap-2">
      {user && /* !user?.is_premium && (user?.credit_balance || 50) < 20 && */
        <Link
          prefetch={false}
          href='/rewards'
          className="flex items-center bg-brand px-3 rounded-full font-semibold text-lg"
        >
          {(() => {
            const today = new Date();

            // Valentine's Day: Feb 14-19
            if (today >= new Date('2025-02-13') && today <= new Date('2025-02-19')) {
              return '❤️';
            }
            // St. Patrick's Day: March 14-19
            if (today >= new Date('2025-03-14') && today <= new Date('2025-03-19')) {
              return '☘️';
            }
            // Easter: April 18-23
            if (today >= new Date('2025-04-18') && today <= new Date('2025-04-23')) {
              return '🥚';
            }
            // Memorial Day (May 27 ±2 days, 2024)
            if (today >= new Date('2025-05-25') && today <= new Date('2025-05-29')) {
              return '🇺🇸';
            }
            // Independence Day: July 1-7
            if (today >= new Date('2025-07-01') && today <= new Date('2025-07-07')) {
              return '🇺🇸';
            }
            // Thanksgiving
            if (today >= new Date('2025-11-20') && today <= new Date('2025-11-28')) {
              return '🦃';
            }
            // Default/Christmas
            return '🎁';
          })()}
        </Link>
      }

      <Link
        prefetch={false}
        href={user?.is_premium ? '/credits' : '/subscriptions'}
        className="flex items-center gap-1 bg-brand px-2 py-0.5 rounded-full"
      >
        <div className="w-5 h-5 rounded-full">
          <img src={CoinImage.src} alt="Coin" className="w-full h-full object-cover rounded-full" />
        </div>
        {user?.id ? (
          <span className="cy-coin-balance font-semibold">
            {user?.credit_balance && user.credit_balance > 1000 ? '1K+' : user?.credit_balance || 0}
          </span>
        ) : (
          <span className="font-medium">Get 50 Free</span>
        )}
      </Link>
    </div>
  );
};
