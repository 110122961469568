import { useState, useMemo } from 'react';

import dynamic from 'next/dynamic';

const Modal = dynamic(() => import('components/modals/Modal').then(({ Modal }) => Modal), { ssr: false });

type Props = {
  onClose: () => void;
};

export const OnboardingWizardModal = ({ onClose }: Props) => {
  const [step, setStep] = useState(0);

  return (
    <Modal nonDismissable className="h-[80vh] flex items-end" bgImage={`public/images/onboarding${step + 1}.png`}>
      {step === 0 && (
        <>
          <div className="flex flex-col text-center">
            <h1 className="text-2xl font-semibold">️Welcome to Krush</h1>
          </div>
          <p className="py-4">
            We think you&apos;ll enjoy your time here.
            <br/>You received <span className="font-semibold text-orange-400">50 credits</span> to start,{' '}
            and you will receive <span className="font-semibold text-orange-400">15 credits daily</span> as a free user.
          </p>
          <button type="submit" className="btn contained primary w-full" onClick={() => setStep(1)}>
            Next
          </button>
        </>
      )}

      {step === 1 && (
        <>
          <div className="flex flex-col text-center">
            <h1 className="text-2xl font-semibold">️For all your Fantasies</h1>
          </div>
          <p className="py-4">
            We&apos;ve created the most advanced models on the market with competitive and transparent pricing.{' '}
            <span className="font-semibold text-orange-400">Each Message cost 1 credit</span>,{' '}
            <span className="font-semibold text-orange-400">Voice Notes are 3 credits</span>,{' '}
            <span className="font-semibold text-orange-400">Photos are 4 credits</span>.
          </p>
          <button type="submit" className="btn contained primary w-full" onClick={() => setStep(2)}>
            Next
          </button>
        </>
      )}

      {step === 2 && (
        <>
          <div className="flex flex-col text-center">
            <h1 className="text-2xl font-semibold">When You&apos;re Ready to Come</h1>
          </div>
          <p className="py-4">
            Don&apos;t settle for the second best. Take a look around, and when you&apos;re ready{' '}
            <span className="font-semibold text-orange-400">subscribe to Premium</span> for{' '}
            <span className="font-semibold text-orange-400">unlimited messages</span> and{' '}
            <span className="font-semibold text-orange-400">exclusive NSFW features</span>.
          </p>
          <button type="submit" className="btn contained primary w-full" onClick={onClose}>
            Get Started
          </button>
        </>
      )}

      <div className="flex justify-center space-x-2 mt-4">
        {[0, 1, 2].map((s) => (
          <div key={s} className={`bg-brand h-2 w-2 rounded-full ${step === s ? 'brightness-100' : 'brightness-50'}`} />
        ))}
      </div>
    </Modal>
  );
};
