import Link from 'next/link';
import { Create } from 'components/icons/Create';
import { Photo } from 'components/icons/Photo';
import { AddPhoto } from 'components/icons/AddPhoto';
import { Crushes } from 'components/icons/Crushes';
import { isKrushAppIOS } from 'utils/userAgentCheck';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type CreateOption = {
  label: string;
  path: string;
  icon: JSX.Element;
  badge?: string;
};

const BASE_OPTIONS: CreateOption[] = [
  { label: 'Create Krush', path: '/create', icon: <Create className="w-7 h-5" /> },
  { label: 'My Krushes', path: '/my-krushes', icon: <Crushes className="w-7 h-5" /> },
  {
    label: 'Photo Studio',
    path: '/studio',
    icon: <AddPhoto className="w-7 h-5" />,
    badge: 'New!'
  },
  {
    label: 'Photo Gallery',
    path: '/gallery',
    icon: <Photo className="w-7 h-5" />,
    badge: 'New!'
  }
];

const CREATE_OPTIONS = BASE_OPTIONS.filter(option => {
  if (isKrushAppIOS()) {
    return !['studio', 'gallery'].includes(option.path.replace('/', ''));
  }
  return true;
});

export const CreateMenu = ({ isOpen, onClose }: Props) => {
  return (
    <>
      <div
        className={`lg:hidden fixed left-0 bottom-0 right-0 bg-primary rounded-t-3xl z-[100] transform
          transition-transform duration-300 ease-in-out pb-8 ${
          isOpen ? 'translate-y-0' : 'translate-y-[400px]'
        }`}
      >
        <div className="h-full relative flex flex-col gap-2 px-4 pb-4">
          <div className="flex justify-center py-4" onClick={onClose} onTouchMove={onClose}>
            <div className="w-12 h-1.5 rounded-full bg-white bg-opacity-20" />
          </div>
          {CREATE_OPTIONS.map(option => (
            <Link
              key={option.path}
              href={option.path}
              className="flex items-center gap-2 p-3 rounded-xl border border-primary"
              onClick={onClose}
            >
              {option.icon}
              {option.label}
              {option.badge && (
                <span className="ml-2 bg-brand text-xs font-bold px-1.5 py-0.5 rounded-full">
                  {option.badge}
                </span>
              )}
            </Link>
          ))}
        </div>
      </div>
      {isOpen && (
        <div
          className="lg:hidden fixed inset-0 z-[99] flex items-center justify-center p-4 backdrop-blur-sm"
          onClick={onClose}
        />
      )}
    </>
  );
};