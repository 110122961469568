import { useEffect, useMemo } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { fetchBillingSubscriptions } from 'api/billing';
import { auth } from 'lib/firebase/firebase-config';
import { clearStorage, getStorageItem } from 'utils/storage';
import { useAuthStore } from 'store/auth';
import { getMe, setToken } from 'api/user';
import { reportAnalyticsEvent } from 'utils/analytics';
import { useModalsStore } from 'store/modals';
import Cookies from 'js-cookie';
import { showToast } from 'components/ui/CustomToast';
import { isCrushAppMobile } from 'utils/userAgentCheck';
import { authStorage } from 'utils/storage/auth';


export const useAuth = () => {
  const { user, setUser, setIsLoading, setIsLogged } = useAuthStore();
  const { setIsOpenAuthModal } = useModalsStore();

  const subscribeToAuthChanges = () =>
    onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        setIsLoading(true);
        const isAppMobile = isCrushAppMobile();
        const previousUser = getStorageItem('user');

        if (firebaseUser) {
          const token = await firebaseUser.getIdToken();
          Cookies.set('token', token);

          // Get session id from indexedDB or set it
          let sessionId = await authStorage.getSessionId();
          if (!sessionId) {
            sessionId = await setToken();
            if (sessionId) {
              const success = await authStorage.setSessionId(sessionId);
              if (success) {
                reportAnalyticsEvent('Set Token Success');
                const newSessionId = await authStorage.getSessionId();
                reportAnalyticsEvent('Set Token Check', { session_id: newSessionId });
              }
              else {
                reportAnalyticsEvent('Set Token Error 4');
              }
            }
            else {
              reportAnalyticsEvent('Set Token Error 2');
              console.log('Setting session id failed');
            }
          }

          const user = await getMe();
          setIsLogged(true);
          try {
            window.posthog.identify(user?.uuid, { email: user?.email, name: user?.custom_attributes.name });
          } catch (error) {}
          await fetchBillingSubscriptions();
        } else if (!isAppMobile && previousUser) {
          // Firebase log out is triggered upstream.  If Firebase logs out itself, ignore.

          // Wait a bit to see if Firebase recovers on its own
          reportAnalyticsEvent('Auth Error');
          setTimeout(() => {
            if (getStorageItem('user')) {
              reportAnalyticsEvent('Token Wait recovered');
            }
            else {
              reportAnalyticsEvent('Token Wait error');
              // setIsOpenAuthModal(true);    // Using session_id now no longer rely only in Firebase
            }
          }, 5000);
        }
      } catch (error) {
        console.error('Auth state change error:', error);
      } finally {
        setIsLoading(false);
      }
    });

  useEffect(() => {
    const initUser = async () => {
      const user = getStorageItem('user');
      const sessionId = await authStorage.getSessionId();
      if (user && sessionId) {
        console.log('Setting user from local storage: ', user.id);
        setUser(user);
        setIsLogged(true);
        await authStorage.setSessionId(sessionId);
      }
    };

    initUser();

    // Listen for token issues before they cause logout
    const tokenUnsubscribe = auth.onIdTokenChanged(
      async (user) => {
        try {
          console.log('Token state changed:', user ? 'valid' : 'invalid');
          if (!user) {
            reportAnalyticsEvent('token_error', {
              reason: 'token_state_changed',
              type: 'invalidated'
            });
          }
        } catch (error) {
          console.error('Token state change handler error:', error);
        }
      },
      (error: any) => {
        try {
          console.error('Token error:', {
            code: error?.code,
            message: error?.message
          });
          reportAnalyticsEvent('token_error', {
            reason: error?.code || 'unknown',
            message: error?.message,
            type: 'error'
          });
        } catch (handlerError) {
          console.error('Token error handler error:', handlerError);
        }
      }
    );

    // Normal auth state changes
    const authUnsubscribe = subscribeToAuthChanges();

    return () => {
      tokenUnsubscribe();
      authUnsubscribe();
    };
  }, []);

  const isUserDetailsPopulated = useMemo(() => {
    if (!user) return false;

    const { custom_attributes } = user;

    return Boolean(custom_attributes?.gender) &&
      Boolean(custom_attributes?.name) &&
      Boolean(custom_attributes?.accept_tos_18)
      ? false
      : true;
  }, [user]);

  return {
    isUserDetailsPopulated
  };
};

export const handleLogout = async (redirectUrl: string) => {
  const { setUser, setIsLogged, setLogoutRedirect } = useAuthStore.getState();

  showToast({ message: 'You have now signed out.', type: 'success' });
  reportAnalyticsEvent('Logout Completed');

  setUser(null);
  setIsLogged(false);
  clearStorage();
  setLogoutRedirect('');
  Cookies.remove('userID');
  Cookies.remove('token');
  window.posthog.reset();
  await authStorage.clearSession();

  window.location.href = redirectUrl || '/';
};
