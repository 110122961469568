import UAParser from 'ua-parser-js';

const getUAParser = (userAgent?: string): UAParser => {
  const ua = typeof window !== 'undefined' ? userAgent || window.navigator.userAgent : userAgent || '';
  const parser = new UAParser(ua);
  return parser;
};

export const isCrushAppIOS = (userAgent?: string): boolean => {
  const parser = getUAParser(userAgent);
  const ua = parser.getUA();
  // console.log('ua', ua);
  return ua.includes('Crush App iOS') || ua.includes('Krush App iOS');
};

export const isKrushAppIOS = (userAgent?: string): boolean => {
  const parser = getUAParser(userAgent);
  const ua = parser.getUA();
  // console.log('ua', ua);
  return ua.includes('Krush App iOS');
};

export const isCrushAppAndroid = (userAgent?: string): boolean => {
  const parser = getUAParser(userAgent);
  const ua = parser.getUA();
  // console.log('ua', ua);
  return ua.includes('Crush App Android') || ua.includes('Krush App Android');
};

export const isCrushAppMobile = (userAgent?: string): boolean => {
  return isCrushAppIOS(userAgent) || isCrushAppAndroid(userAgent);
};

export const getUA = (userAgent?: string): string => {
  const parser = getUAParser(userAgent);
  const ua = parser.getUA();
  return ua;
};

type ClientType = 'ios' | 'android';

/**
 * Compares version strings (e.g., "1.0.0" vs "1.1.2")
 * Returns:
 *  1 if version1 > version2
 *  0 if version1 === version2
 *  -1 if version1 < version2
 */
const compareVersions = (version1: string, version2: string): number => {
  const parts1 = version1.split('.').map(Number);
  const parts2 = version2.split('.').map(Number);

  // Pad with zeros to handle different version length (e.g., "1.0" vs "1.0.0")
  while (parts1.length < parts2.length) parts1.push(0);
  while (parts2.length < parts1.length) parts2.push(0);

  for (let i = 0; i < parts1.length; i++) {
    if (parts1[i] > parts2[i]) return 1;
    if (parts1[i] < parts2[i]) return -1;
  }
  return 0;
};

const getCurrentVersion = (client: ClientType, userAgent?: string): string | null => {
  const parser = getUAParser(userAgent);
  const ua = parser.getUA();

  // Check if it's the correct client
  const isRequestedClient = client === 'ios'
    ? ua.includes('Krush App iOS')
    : ua.includes('Krush App Android');

  if (!isRequestedClient) return null;

  // Extract current version
  const versionMatch = ua.match(new RegExp(`Krush App ${client === 'ios' ? 'iOS' : 'Android'}\\/([0-9.]+)`));
  return versionMatch ? versionMatch[1] : null;
};

export const isMinimalAppVersion = (client: ClientType, minVersion: string, userAgent?: string): boolean => {
  const currentVersion = getCurrentVersion(client, userAgent);
  return currentVersion ? compareVersions(currentVersion, minVersion) >= 0 : false;
};

export const isMaximalAppVersion = (client: ClientType, maxVersion: string, userAgent?: string): boolean => {
  const currentVersion = getCurrentVersion(client, userAgent);
  return currentVersion ? compareVersions(currentVersion, maxVersion) <= 0 : false;
};
